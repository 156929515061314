<template>
    <div class="container">
        <div class="top">

            <div class="top_div">
                <span style="font-size: 13px;">环境：</span>
                <el-radio-group v-model="envRadio" size="mini" @change="envRadioHandler">
                    <el-radio-button label="test">测试</el-radio-button>
                    <el-radio-button label="beta">演示</el-radio-button>
                    <el-radio-button label="prod">正式</el-radio-button>

                </el-radio-group>
            </div>
            <div class="top_div">
                <el-select v-model="pcoValue" clearable placeholder="请选择-pco" size="mini" filterable style="width: 250px;"
                    @change="pcoSelectHandler">
                    <el-option v-for="item in pcoList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>

            <div class="top_div">
                <el-select v-model="opeateValue" clearable placeholder="请选择操作" size="mini" @change="opeateSelcetHandler"
                    :disabled="pcoValue == ''">
                    <el-option v-for="item in opeateList" :key="item.key" :label="item.label" :value="item.key">
                    </el-option>
                </el-select>
            </div>
            <div class="top_div">
                <el-input placeholder="sql关键字查询（逗号分割多条件）" v-model="keyWords" size="mini" style="width: 300px;"
                    :disabled="pcoValue == ''" @blur="keyWordsBlur"> </el-input>
            </div>
            <div class="top_div">
                <el-date-picker size="mini" v-model="time" @blur="changeDateHandler" type="daterange"
                    :disabled="pcoValue == ''" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期" align="right">
                </el-date-picker>
            </div>

        </div>
        <div class="table">
            <el-card class="box-card">
                <el-container>
                    <el-aside width="20%">

                        <el-badge v-for="item in sqlGroupList" :key="item.key" :value="item.value" class="item">
                            <el-button size="small" @click="sqlGroupClick(item.key)">{{ item.key }}</el-button>
                        </el-badge>
                        <div :hidden="sqlGroupList.length > 0" style="line-height: 30;text-align: center;">暂无数据</div>
                        <div style="height: 60px;"></div>

                    </el-aside>
                    <el-container>
                        <el-main>
                          
                            <div style="height: 340px;margin-top: 10px;">
                                <div style="line-height: 30px;color: white;background-color: orange;font-weight: 900;">SQL记录</div>
                                <el-table :data="sqlList" border style="width: 100%" size="mini" @row-click="sqlRowClick">
                                    <el-table-column prop="id" label="记录id" width="80" align="center" >
                                    </el-table-column>
                                    <el-table-column prop="uri" label="请求接口" width="350" align="center">
                                    </el-table-column>
                                    <el-table-column prop="type" label="操作类型" width="150" align="center">
                                    </el-table-column>
                                    <el-table-column prop="sourceTypeDisplay" label="来源" width="150" align="center">
                                    </el-table-column>
                                    <el-table-column prop="sqlStr" label="sql语句" width="500" align="center">
                                        <template slot-scope="scope">
                                            <!-- 在这里通过 scope.row.sqlStr 获取每一行的 sqlStr -->

                                            <el-popover placement="top-start" title="sql语句" width="600" trigger="hover"
                                                :content="scope.row.sqlStr">
                                                <p slot="reference">{{ truncateText(scope.row.sqlStr, 40) }}</p>
                                            </el-popover>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="createTime" label="日期" width="150" align="center">
                                    </el-table-column>

                                  
                                </el-table>
                                <div class="main-page">
                                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                        :current-page="currentPage" :page-sizes="pageSizeArr" :page-size="pageSize"
                                        layout="total, sizes, prev, pager, next, jumper" :total="total">
                                    </el-pagination>
                                </div>
                            </div>
                           
                            <el-input placeholder="关键字查询（日期）" v-model="keyWordsTable" size="mini" style="width: 300px;"  @blur="keyWordsTableBlur"> </el-input>
                            <el-button style="margin-left: 20px;" type="warning" icon=" el-icon-refresh" circle size="mini" @click="keyWordsTableRest"></el-button>
                            <div style="margin-top: 1%;">
                                <div style="line-height: 30px;color: white;background-color: black;font-weight: 900;">HTTP请求日志</div>
                                <el-table :data="httpRequestList" border style="width: 100%" size="mini">
                                <el-table-column prop="record_id" label="日志id" width="80" align="center">
                                </el-table-column>
                                <el-table-column prop="user_name" label="操作人" width="80" align="center">
                                </el-table-column>
                                <el-table-column prop="address" label="IP地址" width="150" align="center">
                                </el-table-column>
                                <el-table-column prop="source_type" label="来源" width="80" align="center">
                                </el-table-column>
                                <el-table-column prop="url" label="请求接口" width="300" align="center">
                                </el-table-column>
                                <el-table-column prop="method" label="请求方式" width="100" align="center">
                                </el-table-column>   
                                <el-table-column prop="request_data" label="请求参数" width="190" align="center">
                                    <template slot-scope="scope">
                                        <!-- 在这里通过 scope.row.sqlStr 获取每一行的 sqlStr -->

                                        <el-popover placement="top-start" title="请求参数" width="600" trigger="hover"
                                            :content="scope.row.request_data">
                                            <p slot="reference">{{ truncateText(scope.row.request_data, 20) }}</p>
                                        </el-popover>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="response_data" label="响应参数" width="280" align="center">
                                    <template slot-scope="scope">
                                        <!-- 在这里通过 scope.row.sqlStr 获取每一行的 sqlStr -->

                                        <el-popover placement="top-start" title="响应参数" width="600" trigger="hover" effect="dark"
                                            :content="scope.row.response_data">
                                            <p slot="reference">{{ truncateText(scope.row.response_data, 40) }}</p>
                                        </el-popover>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="request_time" label="日期" width="150" align="center">
                                </el-table-column>

                            </el-table>
                            <div class="main-page">
                                <el-pagination @size-change="httpHandleSizeChange" @current-change="httpHandleCurrentChange"
                                    :current-page="currentPageHttp" :page-sizes="pageSizeArrHttp" :page-size="pageSizeHttp"
                                    layout="total, sizes, prev, pager, next, jumper" :total="totalHttp">
                                </el-pagination>
                            </div>
                            </div>
                        </el-main>
                    </el-container>
                </el-container>
            </el-card>
        </div>

    </div>
</template>

<script>
import common from "@/util/common.js";
import mhpApi from "@/api/mhpApi.js";
export default {
    data() {
        return {
            envRadio: "test",
            pcoList: [],
            pcoValue: '',
            opeateList: [
                {
                    key: 1,
                    value: 'INSERT',
                    label: '插入'
                }, {
                    key: 2,
                    value: 'UPDATE',
                    label: '更新'
                }, {
                    key: 3,
                    value: 'DELETE',
                    label: '删除'
                }],
            opeateValue: "",
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            time: '',
            startDate: 0,
            endDate: 0,
            sqlGroupList: [],
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            sqlList: [],
            total: 5,
            pageIndex: 1,
            pageSize: 5,
            pageSizeArr: [5],
            currentPage: 1,
            url: "",
            keyWords: "",
            httpRequestList:[],
            totalHttp: 5,
            pageIndexHttp: 1,
            pageSizeHttp: 5,
            pageSizeArrHttp: [5],
            currentPageHttp: 1,
            keyWordsTable:""
        };
    },
    created() {
        this.getPcoSelectData()

    },
    methods: {
        envRadioHandler() {
            console.log(this.envRadio)
            this.getPcoSelectData()
            this.pcoValue = "";
        },
        pcoSelectHandler() {
            this.sqlList=[];
            this.httpRequestList=[];
            this.getSqlGroupData();
        },
        //操作类型
        opeateSelcetHandler() {
            this.getSqlGroupData();
            this.getSqlListData()
            this.getHttpRequestListData()
        },
        //日期选择
        changeDateHandler(val) {
            this.startDate = common.dateFormat(val.value[0], "yyyyMMdd");
            this.endDate = common.dateFormat(val.value[1], "yyyyMMdd")
            this.getSqlGroupData();
        },
        sqlGroupClick(key) {
            this.url = key
            this.keyWordsTable="";
            this.getSqlListData()
            this.getHttpRequestListData()

        },
        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '......';
            } else {
                return text;
            }
        },
        getPcoSelectData() {
            let form = { env: this.envRadio };
            this.$post(mhpApi.getPcoList, form).then((res) => {
                if (res.success && res.statusCode == 200) {
                    this.pcoList = res.data;

                }
            });
        },
        getSqlGroupData() {
            if (this.startDate == 0) {
                this.$message({
                    showClose: true,
                    message: '请选择查询开始日期',
                    type: 'warning'
                });
                return;
            }
            if (this.endDate == 0) {
                this.$message({
                    showClose: true,
                    message: '请选择查询结束日期',
                    type: 'warning'
                });
                return;
            }
            let form = { env: this.envRadio, mid: this.pcoValue, type: this.opeateValue, startDate: this.startDate, endDate: this.endDate, keyWords: this.keyWords };
            this.$post(mhpApi.getSqlGroup, form).then((res) => {
                if (res.success && res.statusCode == 200) {
                    this.sqlGroupList = res.data;

                }
            });
        },
        getSqlListData() {
            let form = { env: this.envRadio, mid: this.pcoValue, type: this.opeateValue, keyWords: this.keyWords, startDate: this.startDate, endDate: this.endDate, url: this.url, pageIndex: this.pageIndex, pageSize: this.pageSize };
            console.log(form)
            this.$post(mhpApi.getSqlList, form).then((res) => {
                if (res.success && res.statusCode == 200) {
                    this.sqlList = res.data.records;
                    this.total = res.data.total;

                }
            });
        },
        getHttpRequestListData() {
            let form = { env: this.envRadio, mid: this.pcoValue, type: this.opeateValue, keyWords: this.keyWordsTable, startDate: this.startDate, endDate: this.endDate, url: this.url, pageIndex: this.pageIndexHttp, pageSize: this.pageSizeHttp };
          
            this.$post(mhpApi.getHttpRecordList, form).then((res) => {
                if (res.success && res.statusCode == 200) {
                    console.log(res.data)
                    this.httpRequestList = res.data.records;
                    this.totalHttp = res.data.total;

                }
            });
        },
        //分页
        handleSizeChange(val) {
            this.pageSize = val;
            this.getSqlListData()
        },
        handleCurrentChange(val) {
            this.pageIndex = val;
            this.getSqlListData()
        },
        httpHandleSizeChange(val) {
            this.pageSizeHttp = val;
            this.getHttpRequestListData()
        },
        httpHandleCurrentChange(val) {
            this.pageIndexHttp = val;
            this.getHttpRequestListData()
        },
        //关键字焦点事件
        keyWordsBlur() {
            this.getSqlGroupData();
        },
        keyWordsTableBlur(){
            this.getHttpRequestListData()
        },
        keyWordsTableRest(){
            this.keyWordsTable="";
            this.getHttpRequestListData()
        },
        sqlRowClick(row){
            this.keyWordsTable=row.createTime;
            this.getHttpRequestListData();
        }
    }
}
</script>

<style scoped>
.container {
    width: 100%;
    height: 95%;
    margin: 5px 0px 0px 0px;


}

.top {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    border-radius: 4px;
    padding: 10px;

}

.top_div {
    margin-left: 15px;
}

.table {
    margin-top: 10px;
}

.el-aside {
    margin-top: 10px;
    background-color: whitesmoke;
    height: 85vh;

}

.el-main {
    text-align: center;
    height: 85vh;
}

.item {
    margin: 20px 150px 0px 10px;
}

.main-page {
    padding: 10px;
    float: right;
}
</style>