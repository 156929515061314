import { render, staticRenderFns } from "./MhpRequestRecord.vue?vue&type=template&id=397a207a&scoped=true&"
import script from "./MhpRequestRecord.vue?vue&type=script&lang=js&"
export * from "./MhpRequestRecord.vue?vue&type=script&lang=js&"
import style0 from "./MhpRequestRecord.vue?vue&type=style&index=0&id=397a207a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "397a207a",
  null
  
)

export default component.exports